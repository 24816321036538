import React from 'react';
import Twitter from '../assets/svg/twitter.svg';
import Instagram from '../assets/svg/instagram.svg';
import LinkedIn from '../assets/svg/linkedin.svg';
import {FiTwitter, FiLinkedin} from 'react-icons/fi';
import {RiMediumLine} from 'react-icons/ri';
import './Footer.scss';
import horizontlogo from '../assets/images/horizontal-logo.png';

const Footer = () => {
  return (
    <div className="footer bg-footer p-5 text-white d-flex">
      <div className="socials">
        <img className="footer-logo" alt="horizont-logo" src={horizontlogo} />
        <div className="text-white-50">CALL US AT</div>
        <a
          href="tel:+44757470622"
          style={{textDecoration: 'none'}}
          className="no-underline fw-bold pb-2">
          +44757470622
        </a>
        <div>
          <a
            id="social-link"
            href="https://www.linkedin.com/company/horizont-software-development/"
            target="_blank">
            <FiLinkedin />
          </a>
          <a
            id="social-link"
            href="https://twitter.com/horizonttech"
            target="_blank">
            <FiTwitter />
          </a>
          <a
            id="social-link"
            href="https://medium.com/@horizonttech"
            target="_blank">
            <RiMediumLine />
          </a>
        </div>
      </div>
      <div className="links">
        <div className="col-6">
          <h5 className="mb-3 fw-bold">LINKS</h5>
          <div className="text-white-50 d-flex flex-column">
            <a className="pb-2 footer-link" href="/">
              HOME
            </a>
            <a className="pb-2 footer-link" href="/services">
              SERVICES
            </a>
            <a className="pb-2 footer-link" href="/events">
              EVENTS
            </a>
            <a className="pb-2 footer-link" href="/blog">
              BLOG
            </a>
            <a className="pb-2 footer-link" href="/contact-us/#contact-form-id">
              CONTACT US
            </a>
          </div>
        </div>
      </div>
      <div className="locations">
        <h5 className="mb-4 fw-bold">LOCATIONS</h5>
        <div className="text-white-50">
          <div>LONDON, UK</div>
          <div>ALBANIA, TIRANA</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
