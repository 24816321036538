import React from 'react';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import './index.scss';

const WhatWeDo = () => {
  return (
    <div className="what-we-do-spacing">
      <HorizonLabsInfo
        coolTitle="SERVICES"
        mainText="WHAT WE DO?"
        style={{
          borderTop: '1px solid',
          width: '9%',
        }}
        subText="We are not just a random group of people that just knows how to do stuff. We are a cross-functional group of people that have everything, and everyone, necessary to produce a working and tested product."
      />
    </div>
  );
};

export default WhatWeDo;
