import React from 'react';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';

const HelpSucceed = () => {
  return (
    <div className="horizon-nice-spacing">
      <HorizonLabsInfo
        mainText="How Can We Help You Succeed?"
        style={{
          borderTop: '4px solid',
          width: '20%',
        }}
        subText="By understanding your business needs and goals, we will use our technologies and skillsets to provide a solution."
      />
    </div>
  );
};

export default HelpSucceed;
