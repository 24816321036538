import React from 'react';
import Header from '../../assets/images/contactUsHeader.png';
import NavBar from '../../hoc/NavBar';
import './ContactUsHeader.scss';

const ContactUsHeader = () => {
  return (
    <div
      className="contact-header-image"
      style={{
        backgroundImage: `url(${Header})`,
      }}>
      <NavBar />
      <div className="text-white m-5 p-4">
        <h1 className="fs-1 font-poppins fw-bolder w-100 main-text text-white">
          YOU THINK!
          <div className="text-brand-purple my-4"> WE MAKE IT REALITY. </div>
        </h1>
        <h4 className="secondary-text mb-5 pt-3 pb-4 reduce-text-width">
          "Simplicity is subtracting the obvious and adding the meaningful."
          With this in mind, we add value to our services whilst making the
          process smooth in order to bring your ideas to life.
        </h4>
      </div>
    </div>
  );
};

export default ContactUsHeader;
