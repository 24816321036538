import React from 'react';
import BusinessPlanning from '../BusinessPlanning/BusinessPlanning';
import './index.scss';
import research from '../../assets/images/research.png';
import analytics from '../../assets/images/analytics.png';
import light from '../../assets/images/light.png';

const PlanningSection = () => {
  const planning = [
    {
      planIcon: research,
      planHeader: `Understand your business objectives`,
      planSubText: `Once we have gotten to know your business objectives, we can create a plan of action to provide an excellent service.`,
    },
    {
      planIcon: analytics,
      planHeader: `Research your users, market, and solution`,
      planSubText: `By researching the client's user base and market, we are able to narrow down what is necessary for a great solution on a case-by-case basis.`,
    },
    {
      planIcon: light,
      planHeader: `Plan how to bring your idea to life`,
      planSubText: `Planning is an important part that allows us to help bring your ideas to reality as well as deliver solid results for your company.`,
    },
  ];

  return (
    <div className="planning-business-flex extra-bottom-space">
      {planning.map((plan) => {
        return (
          <BusinessPlanning
            key={plan.planHeader}
            planningImage={plan.planIcon}
            planningHeader={plan.planHeader}
            planningSubText={plan.planSubText}
          />
        );
      })}
    </div>
  );
};

export default PlanningSection;
