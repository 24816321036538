import React, {useState} from 'react';
import Lottie from 'react-lottie';
import loadingData from '../../assets/loading/horizont-loading.json';
import './Loading.scss';

export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
  };
  return (
    <div id="loading">
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
}
