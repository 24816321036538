import React from 'react';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import './index.scss';

const HorizonLabsInfoExtraSpace = () => {
  return (
    <div className="horizon-nice-spacing">
      <HorizonLabsInfo
        mainText="Collaborative & Scientific Approach"
        style={{
          borderTop: '2px solid',
        }}
        subText="Our custom agile process puts you in control; reduces risk, increases transparency between our team and yours, and allows you to hit aggressive timelines."
      />
    </div>
  );
};

export default HorizonLabsInfoExtraSpace;
