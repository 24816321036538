import React from 'react';
import WhatWeOffer from '../WhatWeOffer/WhatWeOffer';

const WhatWeOfferSection = () => {
  return (
    <div>
      <WhatWeOffer
        headerText="EXPLORE WHAT WE OFFER"
        paragraphOne="At Horizont Labs, we use the latest  technologies to provide high-quality services.
        We provide services such as Web and Mobile App Development, as well as Blockchain Development to benefit our customers."
        paragraphTwo="Once we have established your business objectives, done the necessary market research and created a plan, we are able to use our expertise to provide a solid and scalable solution."
      />
    </div>
  );
};

export default WhatWeOfferSection;
