import React from 'react';
import Checkmark from '../../assets/svg/checkmark.svg';
import Graph from '../../assets/svg/graph.svg';
import './HomeGraph.scss';

const HomeGraph = () => {
  return (
    <div className="graph">
      <div className="text">
        <h3 className="font-poppins m-3">
          Have An Idea? We Will Help Make It Real!
        </h3>
        <div className="m-3">
          <img src={Checkmark} className="me-3" />
          Design Analysis
        </div>
        <div className="m-3">
          <img src={Checkmark} className="me-3" />
          Advanced Prototyping
        </div>
        <div className="m-3">
          <img src={Checkmark} className="me-3" />
          On Time Delivery
        </div>
        <div className="m-3">
          <img src={Checkmark} className="me-3" />
          Custom Code with Full and Exclusive Intellectual Property
        </div>
      </div>
      <img src={Graph} className="graph-img" />
    </div>
  );
};

export default HomeGraph;
