import React from 'react';
import './index.scss';

const HorizonLabsInfo = ({mainText, subText, style, coolTitle}) => {
  return (
    <div className="horizon-labs-info-container">
      <div className="cool-title-div">
        <p className="cool-title">{coolTitle}</p>
      </div>
      <div className="paragraph-spacing header-spacing">
        <h3 className="main-text">{mainText}</h3>
      </div>
      <div className="hr">
        <hr style={style} className="purple-line-width" />
      </div>
      <div className="paragraph-spacing">
        <p className="sub-text">{subText}</p>
      </div>
    </div>
  );
};

export default HorizonLabsInfo;
