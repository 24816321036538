import React from 'react';
import TrustedPartners from '../TrustedPartners/TrustedPartners';
import './index.scss';

const TrustedPartnersExtraSpace = () => {
  return (
    <div className="trusted-partners-extra-spacing">
      {' '}
      <TrustedPartners title="Trusted&nbsp;Partners" />
    </div>
  );
};

export default TrustedPartnersExtraSpace;
