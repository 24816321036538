import React, { useEffect, useState } from 'react';
import Footer from '../hoc/Footer';
import HomeHeader from '../components/layout/HomeHeader';
import HomeServicesBody from '../components/layout/HomeServicesBody';
import DesignAndScale from '../assets/images/designAndScale.png';
import BuildAndLaunch from '../assets/images/buildAndLaunch.png';
import EmergentTechnology from '../assets/images/emergentTechnology.png';
import HomeGraph from '../components/layout/HomeGraph';
import WhatWeDo from '../hoc/WhatWeDo/WhatWeDo';
import Loading from '../hoc/Loading/Loading';
import HorizonLabsInfoMoreSpace from '../hoc/HorizonLabsInfoMoreSpace/HorizonLabsInfoMoreSpace';
import TrustedPartnersExtraSpace from '../hoc/TrustedPartnersExtraSpace/TrustedPartnersExtraSpace';
import HButton from '../components/HButton';

const Home = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = 'Horizont Labs - Home';
    var loaded = sessionStorage.getItem('home-loaded');
    if (!loaded) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
    }
    sessionStorage.setItem('home-loaded', 'true');
  }, []);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div>
        <HomeHeader />
        <WhatWeDo />
        <div className="m-5">
          <HomeServicesBody
            image={DesignAndScale}
            title={'Design & Scale'}
            text={`At Horizont Labs, we continuously improve and adjust our development process to align with our client’s vision. From start to finish, we provide a clear path to solve the client's problem within a process that allows us to create a scalable and reliable product, whilst evading common problems that occur amongst startups in the earlier stage of the product launch process.`}
          />
          <HomeServicesBody
            image={BuildAndLaunch}
            title={'Build & Launch'}
            text={
              'We build web applications, mobile applications, custom content management systems (CMS), Database Driven applications & eCommerce applications. At Horizont Labs, we focus on building a product tailored to business requirements, so you can focus on growing your business successfully.'
            }
            imgLeft={false}
          />
          <HomeServicesBody
            image={EmergentTechnology}
            title={'Emergent Technology'}
            text={
              'We are for clients aiming to evolve their business propositions and solve problems with cutting edge technology. From Artificial Intelligence to Blockchain Development;‏‏‎ ‎‎‎from CyberSecurity to Data Analysis Automation, we have a passion for developing black box technology and strive to bring our customers innovative products that meet their demands. '
            }
          />
          <HorizonLabsInfoMoreSpace />

          <HomeGraph />
        </div>
        <TrustedPartnersExtraSpace />
        <Footer />
      </div>
    );
  }
};

export default Home;
