import React from 'react';
import './ActualSeeMore.scss';
import HButton from '../../components/HButton';
import CoolLeftBackground from '../../assets/images/blogsBackgroundLeft.png';
import CoolRightBackground from '../../assets/images/blogsBackgroundRight.png';
import RightArrow from '../../assets/svg/right_arrow.svg';

const ActualSeeMore = ({
  actualBlogTitle,
  eventBlogTitle,
  leftOrRight,
  content,
  blogId,
}) => {
  const getFirstImageUrl = (html) => {
    const firstImageStartIndex =
      html.indexOf(`&lt;firstimage&gt;`) + `&lt;firstimage&gt;`.length;
    const firstImageEndIndex = html.indexOf(`&lt;/firstimage&gt`);
    const firstImage = html.substring(firstImageStartIndex, firstImageEndIndex);
    return firstImage;
  };
  const getPostDescription = (html) => {
    const descriptionStartIndex =
      html.indexOf(`<description>`) + `<description>`.length;
    const descriptionEndIndex = html.indexOf(`</description>`);
    const description = html.substring(
      descriptionStartIndex,
      descriptionEndIndex,
    );
    return description;
  };

  return (
    <div
      className="blog-background-image blog-opacity"
      style={{
        backgroundImage: `url(${
          leftOrRight ? CoolLeftBackground : CoolRightBackground
        })`,
      }}>
      <div className={`actual-overall-spacing ${leftOrRight && 'leftOrRight'}`}>
        <div className="actual-blog-content">
          <div className="actual-blog-title">
            <h3 className="actual-title-styling actual-bold">
              {actualBlogTitle} {eventBlogTitle}
            </h3>
          </div>
          <div className="actual-blog-box">
            <p className="actual-text-styling actual-text-spacing ">
              <div
                dangerouslySetInnerHTML={{
                  __html: getPostDescription(content),
                }}></div>
            </p>
          </div>
          <div className="button-align">
            <a href={`/blog/${blogId}`}>
              <HButton text={'Read More'} icon={RightArrow} />
            </a>
          </div>
        </div>
        <div className="image-div">
          <img
            src={getFirstImageUrl(content)}
            alt="blogImage"
            className="image-resize"
          />
        </div>
      </div>
    </div>
  );
};

export default ActualSeeMore;
