import React from 'react';
import NavBar from '../hoc/NavBar';
import Footer from '../hoc/Footer';
import BlogArticle from '../hoc/BlogArticle/BlogArticle';
import '../hoc/BlogArticle/BlogArticle.scss';
import ActualSeeMoreSection from '../hoc/ActualSeeMoreSection/ActualSeeMoreSection';
import ActualSeeMore from '../hoc/ActualSeeMore/ActualSeeMore';
import axios from 'axios';
import Loading from '../hoc/Loading/Loading';
import {useEffect, useState} from 'react';
import {store} from 'react-notifications-component';

const BlogPosts = () => {
  const [actualPosts, setActualPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        'https://www.googleapis.com/blogger/v3/blogs/3861590742894716639/posts?key=AIzaSyCK63M3a3vEHg0CSX9o6xbhFB6qPnH-nb8',
      )
      .then(function (response) {
        setActualPosts(response.data.items);
        setLoading(false);
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error getting blog posts',
          message: `${error}`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 4000,
            onScreen: true,
          },
        });
      });
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div>
        <div>
          <div className="dark-blue-style">
            <NavBar />
          </div>
          <div className="blog-header">
            <div className="header-padding header-underline">
              <h1 className="header-styling">BLOG POSTS</h1>
            </div>
          </div>

          {actualPosts.map((element, index) => {
            return (
              <ActualSeeMore
                key={element.id}
                blogId={element.id}
                eventBlogTitle={element.title}
                leftOrRight={index % 2 == 0 ? true : false}
                content={element.content}
              />
            );
          })}
          <Footer />
        </div>
      </div>
    );
  }
};

export default BlogPosts;
