import React from 'react';
import './index.scss';

const HorizonServices = ({
  servicesHeader,
  servicesText,
  icon,
  fontStyle,
  techIconSize,
  flexTechIcon,
}) => {
  return (
    <div className="inline">
      <div className="services-container">
        <div className="circle-div" style={flexTechIcon}>
          <img
            src={icon}
            style={techIconSize}
            alt="iconCircle"
            className="circle-icon"
          />
        </div>

        <div className="sub-text-spacing">
          <h3 className="services-sub-header" style={fontStyle}>
            {servicesHeader}
          </h3>
        </div>
        <div className="sub-text-spacing">
          <p className="services-sub-text" style={fontStyle}>
            {servicesText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HorizonServices;
