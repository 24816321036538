import React from 'react';
import './index.scss';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import HButton from '../../components/HButton';
import RightArrow from '../../assets/svg/right_arrow.svg';
import {store} from 'react-notifications-component';

const EventSubscription = () => {
  return (
    <div className="event-spacing">
      <HorizonLabsInfo
        mainText="Subscribe For Event Updates"
        style={{
          borderTop: '2px solid',
        }}
        subText="Want to be the first to know about new events? Subscribe to our events newsletters and you'll get reminders directly to your inbox."
      />
      <div className="subscribe-button-div">
        <HButton
          text={'Subscribe Now'}
          icon={RightArrow}
          onClick={() => {
            store.addNotification({
              message: `Coming Soon!`,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default EventSubscription;
