import React from 'react';
import './index.scss';

const WhatWeOffer = ({headerText, paragraphOne, paragraphTwo}) => {
  return (
    <div className="offer-container">
      <div className="offer-header-div">
        <h3 className="offer-header">{headerText}</h3>
      </div>
      <div className="offer-text-div">
        <p className="offer-text">{paragraphOne}</p>
        <p className="offer-text">{paragraphTwo}</p>
      </div>
    </div>
  );
};

export default WhatWeOffer;
