import React from 'react';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import './index.scss';

const TopOfBlog = () => {
  return (
    <div className="top-blog-spacing">
      <HorizonLabsInfo
        mainText="Blog"
        style={{
          borderTop: '2px solid',
          width: '2%',
        }}
        subText="Here are the latest blog posts to keep you informed:"
      />
    </div>
  );
};

export default TopOfBlog;
