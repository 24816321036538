import React from 'react';
import './index.scss';
import Comuzi from '../../assets/svg/comuzi.svg';
import Deloitte from '../../assets/svg/deloitte.svg';
import Zilliqa from '../../assets/svg/zilliqa.svg';
import Line from '../../assets/svg/line.svg';
import CasperLogo from '../../assets/images/casper-logo-primary.png';
export default function TrustedPartners({title}) {
  return (
    <div className="trusted-container">
      <div className="trusted-partners">
        <div className="line-spacing-one">
          <img src={Line} alt="line" className="line-sizing" />
        </div>
        <div className="trust">
          <h3 className="trusted-words">{title}</h3>
        </div>
        <div className="line-spacing-two">
          <img src={Line} alt="line" className="line-sizing" />
        </div>
      </div>
      <div className="trusted-images">
        <div className="trusted-partner-element vertical-com-and-del">
          <a href="https://casper.network/en/network" target="_blank">
            <img
              src={CasperLogo}
              alt="Casper"
              className="trust-images-sizing casper-colour"
            />
          </a>
        </div>
        <div className="trusted-partner-element vertical-com-and-del">
          <a href="https://www.comuzi.xyz/" target="_blank">
            <img src={Comuzi} alt="Comuzi" className="trust-images-sizing" />
          </a>
        </div>
        <div className="trusted-partner-element">
          <a href="https://www.zilliqa.com/" target="_blank">
            <img
              src={Zilliqa}
              alt="Zilliqa"
              className="trust-images-sizing height160"
            />
          </a>
        </div>

        <div className="trusted-partner-element vertical-com-and-del">
          <a
            href="https://www2.deloitte.com/uk/en/explore/home.html?adobe_mc_sdid=SDID%3D0650299C22A92697-267272079A4A4DAD%7CMCORGID%3D5742550D515CABFF0A490D44@AdobeOrg%7CTS%3D1634721280&adobe_mc_ref=https:%2F%2Fwww2.deloitte.com%2Fuk%2Fen%2Fexplore%2Fhome.html&icid=bottom_en"
            target="_blank">
            <img
              src={Deloitte}
              alt="Deloitte"
              className="trust-images-sizing"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
