import React, {useState} from 'react';
import HButton from '../HButton';
import emailjs, {EmailJSResponseStatus} from 'emailjs-com';
import './index.scss';
import {store} from 'react-notifications-component';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [project, setProject] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [failedSubmission, setFailedSubmission] = useState(false);
  const [invalidNameMessage, setInvalidNameMessage] = useState(false);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState(false);

  const fullNameRegex = /^[a-zA-Z-'. ]+$/;

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const submit = () => {
    if (name && email && project) {
      if (fullNameRegex.test(name)) {
        setInvalidNameMessage(false);
      } else {
        setFailedSubmission(false);
        setSubmitted(false);
        setInvalidEmailMessage(false);

        setInvalidNameMessage(true);
        return false;
      }

      if (emailRegex.test(email)) {
        setInvalidEmailMessage(false);
      } else {
        setFailedSubmission(false);
        setSubmitted(false);
        setInvalidNameMessage(false);
        setInvalidEmailMessage(true);
        return false;
      }

      const serviceId = 'contact_service';
      const templateId = 'contact_form';
      const userId = 'user_12vo94YlhSelk6gkzG0KT';

      const templateParams = {
        name,
        email,
        project,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then(function (response) {
          console.log(response);
          setFailedSubmission(false);
          setInvalidEmailMessage(false);
          setInvalidNameMessage(false);
          // setSubmitted(true);
          {
            store.addNotification({
              message: `Thank you for your message, we will be in touch in no time!`,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          setSubmitted(false);
          setInvalidEmailMessage(false);
          setInvalidNameMessage(false);
          // setFailedSubmission(true);
          {
            store.addNotification({
              message: `Error. Your details have not been sent to us.`,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        });

      setName('');
      setEmail('');
      setProject('');
    } else {
      alert('Please fill in all fields.');
    }
  };

  return (
    <div className="m-5 border p-4 d-flex flex-column rounded-3">
      <input
        className="input input-styling"
        placeholder="Full Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {invalidNameMessage ? (
        <span className="red-message message-spacing times">
          Please enter a valid name!
        </span>
      ) : null}
      <input
        className="input input-styling"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {invalidEmailMessage ? (
        <span className="red-message message-spacing">
          Please enter a valid email!
        </span>
      ) : null}
      <input
        className="input input-styling"
        placeholder="Tell us about your project"
        value={project}
        onChange={(e) => setProject(e.target.value)}
      />

      <div className="button-and-message-div">
        <div className="message-background">
          {submitted ? (
            <span className="green-message message-spacing times">
              Thank you for your message, we will be in touch in no time!
            </span>
          ) : null}
          {failedSubmission ? (
            <span className="red-message message-spacing">
              Error. Your details have not been sent to us.
            </span>
          ) : null}
        </div>

        <div className="button-adjustment">
          <HButton
            text="Submit"
            className="ms-auto my-5 py-2 me-5 button-styling"
            onClick={submit}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
