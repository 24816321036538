import React from 'react';
import Header from '../../assets/images/homeHeader.png';
import NavBar from '../../hoc/NavBar';
import HButton from '../HButton';
import RightArrow from '../../assets/svg/right_arrow.svg';
import './HomeHeader.scss';

const HomeHeader = () => {
  return (
    <div
      className="background-header-image"
      style={{
        backgroundImage: `url(${Header})`,
      }}>
      <NavBar />
      <div className="text-white">
        <div className="main-header-text ">
          <span className="text-brand-purple font-poppins">Design</span>{' '}
          <span className="text-brand-purple">&middot;</span>{' '}
          <span className="text-brand-purple font-poppins">Build</span>{' '}
          <span className="text-brand-purple">&middot;</span>{' '}
          <span className="text-brand-purple font-poppins">Scale</span>
        </div>
        <div className="secondary-header-text">
          Innovating with emergent technology to create greater economic value.
        </div>
        <div className="btn-part">
          <a href="/contact-us/#contact-form-id">
            <HButton text={'Contact Us'} icon={RightArrow} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
