import React, {useEffect, useState} from 'react';
import Footer from '../hoc/Footer';
import ServicesHeader from '../components/layout/ServicesHeader';
import PlanningSection from '../hoc/PlanningSection/PlanningSection';
import ServicesSection from '../hoc/ServicesSection/ServicesSection';
import HorizonLabsInfoExtraSpace from '../hoc/HorizonLabsInfoExtraSpace/HorizonLabsInfoExtraSpace';
import Technologies from '../hoc/Technologies/Technologies';
import WhatWeOfferSection from '../hoc/WhatWeOfferSection/WhatWeOfferSection';
import Loading from '../hoc/Loading/Loading';

const Services = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = 'Horizont Labs - Services';
    var loaded = sessionStorage.getItem('services-loaded');
    if (!loaded) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
    }
    sessionStorage.setItem('services-loaded', 'true');
  }, []);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="text-brand-purple">
        <ServicesHeader />
        <HorizonLabsInfoExtraSpace />
        <PlanningSection />
        <WhatWeOfferSection />
        <ServicesSection />
        <Technologies />
        <Footer />
      </div>
    );
  }
};

export default Services;
