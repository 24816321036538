import React from 'react';
import './index.scss';

const BusinessPlanning = ({planningImage, planningHeader, planningSubText}) => {
  return (
    <div className="planning-inline">
      <div className="planning-container">
        <div className="drop-div">
          <img src={planningImage} className="drop-icon" alt="iconDrop" />
        </div>
        <div className="planning-paragraph-spacing">
          <h3 className="planning-sub-header">{planningHeader}</h3>
        </div>
        <div className="planning-paragraph-spacing div-3">
          <p className="planning-sub-text">{planningSubText}</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessPlanning;
