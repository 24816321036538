import React from 'react';
import Header from '../../assets/images/servicesHeader.png';
import NavBar from '../../hoc/NavBar';
import HButton from '../HButton';
import './ServicesHeader.scss';
import RightArrow from '../../assets/svg/right_arrow.svg';

const ServicesHeader = () => {
  return (
    <div
      className="services-header-image"
      style={{
        backgroundImage: `url(${Header})`,
      }}>
      <NavBar />
      <div className="text-white">
        <h1 className="main-header-text font-poppins">
          THE LEADING{' '}
          <span className="text-brand-purple"> MOBILE & WEB DEVELOPMENT </span>{' '}
          COMPANY
        </h1>
        <h4 className="secondary-header-text">
          We continuously evolve and adapt our development process to align with
          our client’s vision. From developing the first Proof-of-Concept (POC)
          to becoming an enterprise-ready business, we help our clients with
          building the MVP and avoid technical traps common amongst early-stage
          startups.
        </h4>
        {/* <div className="btn-part">
          <a href="/contact-us">
            <HButton text={'Get In Touch'} className="py-3" icon={RightArrow} />
          </a>
        </div> */}
        <div className="btn-part">
          <a href="/contact-us/#contact-form-id">
            <HButton text={'Get In Touch'} className="" icon={RightArrow} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServicesHeader;
