import React from 'react';
import './HButton.scss';

const HButton = ({text, icon, onClick, className, subscribeHeight}) => {
  return (
    <button
      onClick={onClick}
      className={`main-btn bg-brand-purple rounded-4 border-0 align-content-center white-shadow ${
        icon ? 'py-2 px-3' : 'py-1 px-5'
      } ${className}`}
      style={subscribeHeight}>
      <span className="text-white">{text}</span>
      {icon && <img className="ps-5" src={icon} />}
    </button>
  );
};

export default HButton;
