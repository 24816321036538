import React from 'react';
import './HomeServicesBody.scss';
import HButton from '../HButton';
import RightArrow from '../../assets/svg/right_arrow.svg';

const HomeServicesBody = ({image, title, text, imgLeft = true}) => {
  return (
    <div
      className={`services-parent ${imgLeft == false ? 'parent-reverse' : ''}`}>
      <img className="services-image" src={image} />
      <div className="services-text">
        <h1 className="font-poppins header-center">{title}</h1>
        <div className="text-secondary">{text}</div>
        <div className="multiple-contact-us">
          <a href="/contact-us/#contact-form-id">
            <HButton text={'Contact Us'} icon={RightArrow} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeServicesBody;
