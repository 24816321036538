import React from 'react';
import Lottie from 'react-lottie';
import './NotFound.scss';
import notFoundLoadingData from '../../assets/not-found/not-found.json';

export default function NotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundLoadingData,
  };

  return (
    <div className="not-found-loading">
      <a href="/">
        <Lottie
          options={defaultOptions}
          // height={400}
          // width={400}
          //height and width are 100% by default when they are not specified
          isStopped={false}
          isPaused={false}
        />
      </a>
    </div>
  );
}
