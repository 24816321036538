import React from 'react';
import Header from '../../assets/images/eventsHeader.png';
import NavBar from '../../hoc/NavBar';
import './EventsHeader.scss';

const EventsHeader = () => {
  return (
    <div
      className="events-header-image"
      style={{
        backgroundImage: `url(${Header})`,
      }}>
      <NavBar />
      <div className="text-white">
        <h1 className="main-header-text font-poppins fw-bolder w-50 ">
          CONNECT. <span className="text-brand-purple"> LEARN. </span> IMAGINE.
        </h1>
        <h4 className="secondary-header-text mb-5 pt-3 pb-4">
          Once we get to know our clients and understand their requirements, we
          will be able to provide a great product to best fit our client’s
          needs.
        </h4>
      </div>
    </div>
  );
};

export default EventsHeader;
