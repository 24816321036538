import React from 'react';
import './BlogArticle.scss';
import NavBar from '../../hoc/NavBar';
import Footer from '../../hoc/Footer';
import DesignAndScale from '../../assets/images/designAndScale.png';
import {RiShareForward2Fill} from 'react-icons/ri';
import HomeHeader from '../../assets/images/homeHeader.png';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import Loading from '../Loading/Loading';
import {store} from 'react-notifications-component';
import ActiveDot from '../../assets/images/activeDot.png';
import moment from 'moment';
import NotFound from '../NotFound/NotFound';

const BlogArticle = () => {
  const history = useHistory();

  const [realPost, setActualRealPost] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://www.googleapis.com/blogger/v3/blogs/3861590742894716639/posts/${history.location.pathname.substr(
          6,
        )}?key=AIzaSyCK63M3a3vEHg0CSX9o6xbhFB6qPnH-nb8`,
      )
      .then(function (response) {
        setActualRealPost(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        // store.addNotification({
        //   title: 'Error getting blog post',
        //   message: `${error}`,
        //   type: 'danger',
        //   insert: 'top',
        //   container: 'top-right',
        //   animationIn: ['animate__animated', 'animate__fadeIn'],
        //   animationOut: ['animate__animated', 'animate__fadeOut'],
        //   dismiss: {
        //     duration: 4000,
        //     onScreen: true,
        //   },
        // });

        // if (error.status === 404) {
        //   window.location.href = '/';
        //   return;
        // }

        console.log(error.response.status);
        if (error.response.status === 404 || (error.response.status = 400)) {
          window.location.href = '/404';
          return;
        }
      });
  }, []);

  const getFirstImageUrl = (html) => {
    const firstImageStartIndex =
      html.indexOf(`&lt;firstimage&gt;`) + `&lt;firstimage&gt;`.length;
    const firstImageEndIndex = html.indexOf(`&lt;/firstimage&gt`);
    const firstImage = html.substring(firstImageStartIndex, firstImageEndIndex);
    return firstImage;
  };

  const getPostMainContent = (html) => {
    const mainContentStartIndex =
      html.indexOf(`<main-content>`) + `<main-content>`.length;
    const mainContentEndIndex = html.indexOf(`</main-content>`);
    const mainContent = html.substring(
      mainContentStartIndex,
      mainContentEndIndex,
    );
    return mainContent;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="blog-article">
      <div className="dark-blue-style">
        <NavBar />
      </div>

      <div className="top-section">
        <div className="flex-top-section">
          <div>
            <p className="purple-title"></p>
          </div>
          <p className="big-title">{realPost.title}</p>
          <p className="date-info">
            By <span className="author">{realPost.author.displayName}</span>{' '}
            {moment(realPost.published).format('DD/MM/YYYY')}
          </p>
          <div className="share-div">
            <button
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                store.addNotification({
                  message: `URL copied to clipboard!`,
                  type: 'success',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 3000,
                    onScreen: true,
                  },
                });
              }}
              className="no-style button-flex">
              <div className="true-icon-space pointer">
                <RiShareForward2Fill color="#707070" size="2.2vw" />
              </div>
              <div className="share-space">
                <p className="share">Share</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="article-image-div">
        <img
          className="article-image-resize"
          src={getFirstImageUrl(realPost.content)}
          alt="blogImageHere"
        />
      </div>
      <div className="article-text-area">
        <div className="article-text-width">
          <p className="article-text">
            <div
              dangerouslySetInnerHTML={{
                __html: getPostMainContent(realPost.content),
              }}
            />
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogArticle;
