import 'bootstrap/scss/bootstrap.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Home from './views/Home';
import Services from './views/Services';
import AboutUs from './views/AboutUs';
import Events from './views/Events';
import ContactUs from './views/ContactUs';
import BlogPosts from './views/BlogPosts';
import ActualSeeMoreSection from './hoc/ActualSeeMoreSection/ActualSeeMoreSection';
import BlogArticle from './hoc/BlogArticle/BlogArticle';
import ReactNotification from 'react-notifications-component';
import NotFound from './hoc/NotFound/NotFound';


const App = () => {
  return (
    <Router>
      <ReactNotification />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/blog" component={BlogPosts} />
        <Route exact path="/blog/:id" component={BlogArticle} />
        <Route exact path="/contact-us" component={ContactUs} />
        {/* <Route component={NotFound} /> */}
        {/* <Route path="*" component={NotFound} /> */}
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
};

export default App;
