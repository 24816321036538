import React, {useEffect, useState} from 'react';
import Footer from '../hoc/Footer';
import EventsHeader from '../components/layout/EventsHeader';
import HomeServicesBody from '../components/layout/HomeServicesBody';
import Table from '../assets/images/arrangementTable.png';
import TopOfBlog from '../hoc/TopOfBlog/TopOfBlog';
import EventSubscription from '../hoc/EventSubscription/EventSubscription';
import TrustedPartners from '../hoc/TrustedPartners/TrustedPartners';
import ActualSeeMore from '../hoc/ActualSeeMore/ActualSeeMore';
import Loading from '../hoc/Loading/Loading';
import axios from 'axios';
import {store} from 'react-notifications-component';

const Events = () => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get(
        'https://www.googleapis.com/blogger/v3/blogs/3861590742894716639/posts?key=AIzaSyCK63M3a3vEHg0CSX9o6xbhFB6qPnH-nb8',
      )
      .then(function (response) {
        setPosts(response.data.items);
        setLoading(false);
      })
      .catch(function (error) {
        store.addNotification({
          title: 'Error getting blog posts',
          message: `${error}`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 4000,
            onScreen: true,
          },
        });
      });
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div>
        <EventsHeader />
        <HomeServicesBody
          image={Table}
          title="HOW WE ARRANGE"
          text={
            <div>
              <div>
                Our clients are number one priority for us. They come first. We
                respect our client and their vision. Our goal is to collaborate
                with them in order to create a great product for their company.
              </div>
              <br />
              <div>
                Using the latest technologies is how we do our work. This
                approach allows innovation so we work better, faster and more
                complete.
              </div>
            </div>
          }
          imgLeft={false}
        />
        <EventSubscription />
        <TopOfBlog />

        {posts.map((element, index) => {
          if (index < 3) {
            return (
              <ActualSeeMore
                key={element.id}
                blogId={element.id}
                eventBlogTitle={element.title}
                leftOrRight={index % 2 == 0 ? true : false}
                content={element.content}
              />
            );
          }
        })}
        <TrustedPartners title="Excited&nbsp;Partners" />
        <Footer />
      </div>
    );
  }
};

export default Events;
