import React, {useEffect, useState} from 'react';
import Footer from '../hoc/Footer';
import ContactUsHeader from '../components/layout/ContactUsHeader';
import ContactForm from '../components/layout/ContactForm';
import OurLocations from '../hoc/OurLocations/OurLocations';
import HelpSucceed from '../hoc/HelpSucceed/HelpSucceed';
import Loading from '../hoc/Loading/Loading';

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = 'Horizont Labs - Contact Us';
    var loaded = sessionStorage.getItem('contact-loaded');
    if (!loaded) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } else {
      setLoading(false);
    }
    sessionStorage.setItem('contact-loaded', 'true');
  }, []);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div>
        <ContactUsHeader />
        <a id="contact-form-id">
          <HelpSucceed />
        </a>
        <ContactForm />

        <OurLocations />
        <Footer />
      </div>
    );
  }
};

export default ContactUs;
