import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import HButton from '../components/HButton';
import ActiveDot from '../assets/images/activeDot.png';
import BurgerMenu from '../assets/svg/burger-menu.js';
import './NavBar.scss';
import horizontlogo from '../assets/images/horizontal-logo.png';

const NavBar = () => {
  const route = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setOpenMenu(false);
  }, [route.pathname]);

  return (
    <div className={openMenu && 'bg-shadow'}>
      <div className="navbar bg-transparent mx-sm-5 mx-3 py-3">
        <div
          onClick={() => {
            window.location.href = '/';
          }}
          className="logo font-poppins text-uppercase navbar-text text-brand-purple fs-3">
          <img className="header-logo" alt="horizont-logo" src={horizontlogo} />
        </div>
        <div
          className="hide-lg"
          onClick={() => setOpenMenu((prevState) => !prevState)}>
          <BurgerMenu fill={openMenu ? '#b64fc7' : 'white'} />
        </div>
        <div className="links-lg hide-sm">
          <NavLink
            exact
            to="/"
            className="nav-tab"
            activeClassName="active-nav-tab">
            Home
            {route.pathname === '/' ? (
              <img src={ActiveDot} className="active-dot" height={11} />
            ) : (
              <div style={{height: 11}} />
            )}
          </NavLink>
          <NavLink
            to="/services"
            className="nav-tab"
            activeClassName="active-nav-tab">
            Services
            {route.pathname === '/services' ? (
              <img src={ActiveDot} className="active-dot" height={11} />
            ) : (
              <div style={{height: 11}} />
            )}
          </NavLink>

          <NavLink
            to="/events"
            className="nav-tab"
            activeClassName="active-nav-tab">
            Events
            {route.pathname === '/events' ? (
              <img src={ActiveDot} className="active-dot" height={11} />
            ) : (
              <div style={{height: 11}} />
            )}
          </NavLink>
          <NavLink
            to="/blog"
            className="nav-tab"
            activeClassName="active-nav-tab">
            Blog
            {route.pathname.substring(0, 5) === '/blog' ? (
              <img src={ActiveDot} className="active-dot" height={11} />
            ) : (
              <div style={{height: 11}} />
            )}
          </NavLink>
          <NavLink to="/contact-us" className="nav-tab">
            <HButton text="Contact Us" className="bg-brand-pink py-3" />
            <div style={{height: 11}} />
          </NavLink>
        </div>
      </div>
      {openMenu && (
        <div className="burger-menu">
          <NavLink
            exact
            to="/"
            className="burger-link"
            activeClassName="active-burger-link">
            Home
          </NavLink>
          <NavLink
            exact
            to="/services"
            className="burger-link"
            activeClassName="active-burger-link">
            Services
          </NavLink>
          <NavLink
            exact
            to="/events"
            className="burger-link"
            activeClassName="active-burger-link">
            Events
          </NavLink>
          <NavLink
            exact
            to="/blog"
            className="burger-link"
            activeClassName="active-burger-link">
            Blog
          </NavLink>
          <NavLink
            exact
            to="/contact-us"
            className="burger-link"
            activeClassName="active-burger-link">
            Contact Us
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default NavBar;
