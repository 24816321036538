import React from 'react';

import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import './index.scss';

const OurLocations = () => {
  return (
    <div>
      {' '}
      <div className="more-space">
        <HorizonLabsInfo
          mainText="Our Locations"
          style={{
            borderTop: '3px solid',
            width: '9%',
          }}
          subText="We are based in Central London, Hatton Garden. If you ever need to contact us, use the provided details below and we will get back to you as soon as we can."
        />
      </div>
      <div className="location-container">
        <div className="location-address-div">
          <div className="address-info">
            <div className="purple-circle-container ">
              <div className="glowing-purple-circle"></div>
            </div>
            <div className="address">
              <h3 className="address-styling boldInfo width-hatton">
                North West House, 119 Marylebone Rd, London, England, NW1 5P
              </h3>
              <p className="london">London, UK</p>
            </div>
          </div>
          <div className="email-phone-info">
            <div className="purple-circle-container">
              <div className="glowing-purple-circle"></div>
            </div>
            <div className="address">
              <p className="address-styling">
                Email: <span className="boldInfo">info@horizontlabs.com</span>
              </p>
              <p className="address-styling phone-line-height">
                Phone:{' '}
                <a href="tel: +44757470622" className="boldInfo phone-height">
                  +44757470622
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="location-map-div">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.569116926834!2d-0.16347348427637204!3d51.52112061752095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ac971554813%3A0xa1f270c7bda84b6e!2s119%20Marylebone%20Rd%2C%20London%20NW1%205PN!5e0!3m2!1sen!2suk!4v1634228755294!5m2!1sen!2suk"
            width="600"
            height="650"
            style={{border: '0'}}
            allowFullScreen=""
            loading="lazy"
            className="location-map-sizing"></iframe>
        </div>
      </div>
    </div>
  );
};

export default OurLocations;
