import React from 'react';
import HorizonLabsInfo from '../HorizonLabsInfo/HorizonLabsInfo';
import './index.scss';
import HButton from '../../components/HButton';
import RightArrow from '../../assets/svg/right_arrow.svg';

const HorizonLabsInfoMoreSpace = () => {
  return (
    <div className="horizon-good-spacing">
      <HorizonLabsInfo
        mainText="Build Scalable Digital Products With A Partner Who Understands The Journey"
        style={{
          borderTop: '1px solid',
          width: '10%',
        }}
        subText="If you have a startup idea about a mobile app or a web application, contact us and we will help to breakdown your requirements using the latest technologies."
      />
      <div className="digitalProductButton">
        <a href="/contact-us/#contact-form-id">
          <HButton text={'Contact Us'} icon={RightArrow} />
        </a>
      </div>
    </div>
  );
};

export default HorizonLabsInfoMoreSpace;
